/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  background-color: #FAFAFA;
  -webkit-user-select: none;
          user-select: none;
}
@font-face {
  font-family: Acme;
  src: url('Acme-Regular.ttf');
}
@font-face {
  font-family: PoppinsLight;
  src: url('Poppins-Light.ttf');
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3N0eWxlcy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLDhFQUFBO0FBQ0E7RUFDRSxTQUFBO0VBQ0EseUJBQUE7RUFDQSx5QkFBQTtVQUFBLGlCQUFBO0FBQ0Y7QUFFQTtFQUNFLGlCQUFBO0VBQ0EsNEJBQUE7QUFDRjtBQUNBO0VBQ0UseUJBQUE7RUFDQSw2QkFBQTtBQUNGIiwiZmlsZSI6InN0eWxlcy5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLyogWW91IGNhbiBhZGQgZ2xvYmFsIHN0eWxlcyB0byB0aGlzIGZpbGUsIGFuZCBhbHNvIGltcG9ydCBvdGhlciBzdHlsZSBmaWxlcyAqL1xuYm9keSB7XG4gIG1hcmdpbjogMDtcbiAgYmFja2dyb3VuZC1jb2xvcjogI0ZBRkFGQTtcbiAgdXNlci1zZWxlY3Q6IG5vbmU7XG59XG5cbkBmb250LWZhY2Uge1xuICBmb250LWZhbWlseTogQWNtZTtcbiAgc3JjOiB1cmwoJ2Fzc2V0cy9mb250cy9BY21lL0FjbWUtUmVndWxhci50dGYnKTtcbn1cbkBmb250LWZhY2Uge1xuICBmb250LWZhbWlseTogUG9wcGluc0xpZ2h0O1xuICBzcmM6IHVybCgnYXNzZXRzL2ZvbnRzL1BvcHBpbnMvUG9wcGlucy1MaWdodC50dGYnKTtcbn1cbiJdfQ== */