/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  background-color: #FAFAFA;
  user-select: none;
}

@font-face {
  font-family: Acme;
  src: url('assets/fonts/Acme/Acme-Regular.ttf');
}
@font-face {
  font-family: PoppinsLight;
  src: url('assets/fonts/Poppins/Poppins-Light.ttf');
}
